<template>
  <div>
    <div class="d-flex typography">
      <div class="container">
        <div class="card rounded-0 border-0 bg-white px-4 pt-3 pb-6">
          <div class="card-body px-0 pt-4 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-12">

                  <div class="form-login mb-4">

                    <div v-if="isLoadingCountries" class="card-deck justify-content-center align-items-center">
                      <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
                    </div>

                    <form v-else>

                      <div class="form-group mb-2" v-if="countries.length > 0">
                        <label for="countryId">Select Country</label>
                        <select class="form-select form-control font-size-lg  form-control-lg bg-transparent py-0"
                          @change="onFilterStateByCountryId" :class="{ 'is-invalid': errors.countryId }"
                          v-model="details.countryId" name="countryId" :disabled="isLoading ? '' : isLoading"
                          autocomplete="off">

                          <option v-for="country in countries" :key="country.id" :value="country.id">
                            {{ country.countryName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.countryId">
                          {{ errors.countryId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2" v-if="states.length > 0">
                        <label for="stateId">Select State</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0"
                          @change="onFilterCityByStateId" :class="{ 'is-invalid': errors.stateId }"
                          v-model="details.stateId" :disabled="isLoading ? '' : isLoading" autocomplete="off">
                          <option v-for="state in states" :key="state.id" :value="state.id">
                            {{ state.stateName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.stateId">
                          {{ errors.stateId[0] }}
                        </div>
                      </div>


                      <div class="form-group mb-2" v-if="cities.length > 0">
                        <label for="cityId">Select City</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0"
                          :class="{ 'is-invalid': errors.cityId }" v-model="details.cityId"
                          :disabled="isLoading ? '' : isLoading" autocomplete="off">

                          <option v-for="item in cities" :key="item.id" :value="item.id">
                            {{ item.cityName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.cityId">
                          {{ errors.cityId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2" v-if="cities.length > 0">
                        <label for="grideCode">Gridcode</label>
                        <input id="grideCode" type="text"
                          class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.grideCode }"  v-on:keyup="searchGrideCode"
                          v-model="details.grideCode" placeholder="Enter gridcode"
                          :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.grideCode">
                          {{ errors.grideCode[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2" v-if="gridecode && gridecode.title">
                        <label for="address">Address</label>
                        <input id="address" type="text"
                          class="form-control font-size-lg  form-control-lg bg-transparent" v-model="gridecode.title"
                          :disabled="true" />

                      </div>

                      <div class="form-group mb-2" v-if="cities.length > 0">
                        <label for="telephone">Primary phone number</label>
                        <input id="telephone" type="text"
                          class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.telephone }" v-model="details.telephone"
                          placeholder="Enter primary phone number"
                          :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.telephone">
                          {{ errors.telephone[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2" v-if="cities.length > 0">
                        <label for="telephone2">Phone number(optional)</label>
                        <input id="telephone2" type="text"
                          class="form-control font-size-lg  form-control-lg bg-transparent" autocomplete="off"
                          :class="{ 'is-invalid': errors.telephone2 }" v-model="details.telephone2"
                          placeholder="Enter phone number"
                          :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.telephone2">
                          {{ errors.telephone2[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2" v-if="cities.length > 0">
                        <label for="landmark">Land mark</label>
                        <input id="landmark" type="text"
                          class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.landmark }" v-model="details.landmark"
                          placeholder="Enter landmark" autocomplete="off"
                          :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.landmark">
                          {{ errors.landmark[0] }}
                        </div>
                      </div>


                      <div class="form-group mt-6" v-if="errors.message">
                      <MessageErrorFadeOut v-bind:message="errors.message" v-bind:show="errors && errors.message?true:false" />
                      </div>
                      <button v-if="cities.length > 0" type="button" @click="createBusinessAddress"
                        :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched"
                        class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                        <span v-if="!isLoading"> Next </span>
                        <span v-if="isLoading">
                          <i class="fas fa-circle-notch fa-spin"></i> Creating...</span>
                      </button>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import {
    MoonLoader
  } from "@saeris/vue-spinners";
    import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  export default {
    name: "CreateMyBranchBusinessAddress",

    data: function () {
      return {
        isLoading: false,
        isLoadingCountries: true,
        isGridCodeSearched: false,

        details: {
          countryId: '',
          grideCode: '',
          cityId: '',
          stateId: '',
          telephone: '',
          telephone2: '',
          address: '',
          landmark: '',
          partnerId: this.$route.params.partnerId
        },
      };
    },
    components: {
      MoonLoader,MessageErrorFadeOut
    },
    computed: {
      ...mapGetters("businessAddress", ['countries', 'states', 'cities', "gridecode"]),
      ...mapGetters(["errors", "isSuccess"]),
    },

    mounted() {
      this.$store.commit("businessAddress/setGridecode", {});
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
      this.getCountries().then(() => {
        this.isLoadingCountries = false
      });

      if (!this.$route.params.partnerId) {
        this.$router.push({
          name: "Home"
        })

      }

    },

    methods: {
      ...mapActions("businessAddress", ["sendBusinessAddressRequest", "getCountries", "getStates", "getCities",
        "partnerGridSearch"
      ]),
    validateMask:function(event){
          event.preventDefault();
            const code = event.keyCode;
            const val = event.currentTarget.value;
            const len = val.length;
             if(!((code >= 48 && code <= 57) || code === 8)) {
                event.preventDefault();
            }
            if(len === 4) {
                event.currentTarget.value += '-'
            }
            if(len === 9) {
                event.preventDefault();
            }
      },
      searchGrideCode: function (event) {
        event.preventDefault();

        this.validateMask(event)

        let gridCodeValue = event.target.value;

        if (gridCodeValue.length == 9) {

          let countryDetail = this.countries.length > 0 && this.details && this.details.countryId ? this.countries
            .find(country => this.details.countryId == country.id) : null;

          this.isGridCodeSearched = true;
          this.$store.commit("setErrors", {});

          let data = {
            gridCode: gridCodeValue,
            countryCode: countryDetail.countryCode
          };
          return this.partnerGridSearch(data).then(() => this.isGridCodeSearched = false);


        }
      },

      onFilterStateByCountryId: function (event) {

        const countryId = event.target.value;

        if (countryId) {
          this.isLoading = true;
          this.getStates(countryId).then(() => this.isLoading = false);
        }

      },
      onFilterCityByStateId: function (event) {

        const stateId = event.target.value;

        if (stateId) {
          this.isLoading = true;
          return this.getCities(stateId).then(() => this.isLoading = false);
        }

      },
      createBusinessAddress: function () {
        this.isLoading = true;
        this.details.partnerId = this.$route.params.partnerId;
        this.sendBusinessAddressRequest(this.details).then(() => {
          this.isLoading = false;
          if (this.isSuccess) {

            this.$notify({
              group: "app",
              type: "success",
              title: "Create",
              text: "Business address added successfully",
              duration: 10000,
              speed: 1000,
            });

            this.$router.push({
              name: 'VerifyBranchBusinessAddressPhoneStepper',
              params: {
                partnerId: this.$route.params.partnerId,
                parentPartnerId: this.$route.params.parentPartnerId
              }
            })

          }
        });
      },

    },
  };
</script>