<template>
  <div>
    <div class="d-flex typography">
      <div class="container">
        <div class="card rounded-0 border-0 bg-white">
          <div class="card-body px-0 pt-0 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="form-login mb-4">
                    <form>

                      <div class="form-group mb-2">
                        <label for="businessCategoryId">Category</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0" :class="{ 'is-invalid': errors.businessCategoryId }"
                          v-model="details.businessCategoryId" :disabled="isLoading ? '' : isLoading">
                          <option value="">All Categories</option>

                          <option v-for="item in businessCategories" :key="item.id" :value="item.id">
                            {{ item.name }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.businessCategoryId">
                          {{ errors.businessCategoryId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="businessName">Branch Name</label>
                        <input id="businessName" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.businessName }" v-model="details.businessName"
                          placeholder="Enter business name" :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.businessName">
                          The branch name field is required.
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="businessEmail">Email</label>
                        <input id="businessEmail" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.businessEmail }" v-model="details.businessEmail"
                          placeholder="Enter business email" :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.businessEmail">
                          {{ errors.businessEmail[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <label for="website">Website</label>
                        <input id="website" type="text" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{ 'is-invalid': errors.website }"
                          v-model="details.website" placeholder="ex: https://plectrumbusiness.com"
                          :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.website">
                          {{ errors.website[0] }}
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <label for="description">Description</label>
                        <textarea id="description" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{ 'is-invalid': errors.description }"
                          v-model="details.description" height="300px" placeholder="Enter description"
                          :disabled="isLoading ? '' : isLoading"></textarea>

                        <div class="invalid-feedback" v-if="errors.description">
                          {{ errors.description[0] }}
                        </div>
                      </div>


                      <div class="form-group mt-6" v-if="errors.message">
                        <MessageErrorFadeOut v-bind:message="errors.message" v-bind:show="errors && errors.message?true:false" />
                       
                      </div>
                      <button type="button" @click="updateBusinessLocation" :disabled="isLoading ? '' : isLoading"
                        class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                        <span v-if="!isLoading"> Update </span>
                        <span v-if="isLoading">
                          <i class="fas fa-circle-notch fa-spin"></i>
                          Updating...</span>
                      </button>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
   import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  export default {
    name: "EditMyBusinessLocation",
    props: ["partnerId"],
    data: function () {
      return {
        isLoading: false,
        businessLocation: null,
        details: {
          businessName: '',
          businessEmail: '',
          description: '',
          website: '',
          logo: null,
          businessCategoryId: null,
          isHeadQuarter: 0,
          headQuarterId: null

        },
      };
    },
     components: {
      MessageErrorFadeOut
    },
    computed: {
      ...mapGetters("businessLocation", ['businessCategories']),
      ...mapGetters(["errors", "isSuccess"]),
    },

    async mounted() {

      this.getBusinessCategories();
      this.businessLocation = await this.getBusinessLocation(this.partnerId);
      this.details.description = this.businessLocation ? this.businessLocation.description : '';
      this.details.website = this.businessLocation ? this.businessLocation.website : '';
      this.details.businessEmail = this.businessLocation ? this.businessLocation.businessEmail : '';
      this.details.businessCategoryId = this.businessLocation ? this.businessLocation.businessCategoryId : '';
      this.details.businessName = this.businessLocation ? this.businessLocation.businessName : '';
      this.details.partnerId = this.businessLocation ? this.businessLocation.partnerId : '';
      this.details.headQuarterId = this.businessLocation ? this.businessLocation.headQuarterId : '';
      this.details.isHeadQuarter = this.businessLocation ? this.businessLocation.isHeadQuarter : '';
      this.details.logo = this.businessLocation ? this.businessLocation.logo : '';

      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);

    },

    methods: {
      ...mapActions("businessLocation", ["getBusinessCategories", "getBusinessLocation",
        "updateBusinessLocationRequest"]),

      updateBusinessLocation: function () {
        this.isLoading = true;
        this.details.headQuarterId = this.businessLocation ? this.businessLocation.headQuarterId : '';
        this.updateBusinessLocationRequest(this.details).then(() => {
          this.isLoading = false;
          if (this.errors && this.errors.message) {
            this.errorMessage = this.errors.message;
            this.$notify({
              group: "app",
              type: "error",
              title: "Something went wrong!",
              text: this.errors.message,
              duration: 10000,
              speed: 1000,
            });
          }
          if (this.isSuccess) {
            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Branch details updated successfully",
              duration: 10000,
              speed: 1000,
            });

          }
        });
      },

    },
  };
</script>